.mobile-my-drawer-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .logout-btn {
    margin: 0 16px;
  }
}

@app-prefix: ewell;@ant-prefix: ewell;