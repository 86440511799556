@import '@assets/theme/color.less';

.common-project-status-tag {
  padding: 0 8px;
  border-radius: 200px;
  background-color: @bg-2;
  &.common-project-status-tag-purple {
    background-color: @bg-3;
    .@{app-prefix}-typography {
      color: @font-4;
    }
  }
}

@app-prefix: ewell;@ant-prefix: ewell;