@import '@assets/theme/constants.less';
@import '@assets/theme/color.less';

.common-card-wrapper {
  border: 1px solid @border-1;
  border-radius: 8px;
  .title-wrapper {
    padding: 16px 24px;
    .title {
      .text-overflow(1);
    }
  }
}

@app-prefix: ewell;@ant-prefix: ewell;