@import '../../../../assets/theme/color.less';

.wallet-info-item-wrap {
  display: flex;
  width: 100%;
  background-color: @bg-9;
  padding: 16px;
  cursor: pointer;
  &:hover {
    background-color: @bg-10;
  }

  img {
    height: 20px;
    width: 20px;
    margin-right: 8px;
    flex-shrink: 0;
    margin-top: 1px;
  }
  .wallet-item-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    .wallet-item-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: @font-1;
    }
  }
}

.mobile-site {
  .wallet-info-item-wrap {
    &:hover {
      background-color: unset;
    }
    &:active {
      background-color: @bg-10;
    }
  }
}

@app-prefix: ewell;@ant-prefix: ewell;