@import '../../assets/theme/color.less';

.welcome-modal-wrap {
  .terms-info {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 24px;
  }
}

@app-prefix: ewell;@ant-prefix: ewell;