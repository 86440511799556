.infinite-loader {
  width: 100%;
  margin-top: 20px;
}
.infinite-scroll-to-top {
  position: absolute;
  bottom: 15%;
  right: 60px;
}

.mobile-site {
  .infinite-scroll-to-top {
    right: 10%;
  }
}
@app-prefix: ewell;@ant-prefix: ewell;