.sand-glass-loading {
  animation: rotate 0.9s ease-in-out infinite;

}

@keyframes rotate {
  0% {
      transform: rotate(0deg);
  }
  30% {
      transform: rotate(360deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
@app-prefix: ewell;@ant-prefix: ewell;