@import '../../assets/theme/constants.less';

.portkey-assets {
  .assets-back-wrap {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

@app-prefix: ewell;@ant-prefix: ewell;