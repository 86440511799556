@import '@assets/theme/color.less';

.esteps {
  .@{app-prefix}-steps-item-wait .@{app-prefix}-steps-item-icon >.@{app-prefix}-steps-icon {
    color: @font-9;
  }

  .@{app-prefix}-steps-item-finish .@{app-prefix}-steps-item-icon {
    background-color: transparent;
   }
  
}
@app-prefix: ewell;@ant-prefix: ewell;