.edit-information {
  .edit-breadcrumb {
    margin-top: 24px;
  }
  .edit-form {
    max-width: 898px;
    margin: auto;
    padding: 24px 16px;
  }
}

@app-prefix: ewell;@ant-prefix: ewell;