@import '@assets/theme/color.less';

.common-table {
  .table-empty-wrapper {
    margin: 48px 0;
    .table-empty-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: @font-1;
    }
  }
  .table-loading-wrapper .@{app-prefix}-spin-spinning {
    max-height: none;
    .@{app-prefix}-spin-dot {
      width: auto;
      height: auto;
      margin: -35px 0 0 -100px;
    }
    .table-loading {
      width: 200px;
      padding: 24px;
      background-color: @bg-9;
      border-radius: 8px;
      box-shadow: 0 4px 16px 0 rgb(7 10 38 / 16%);
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
  }
}

@app-prefix: ewell;@ant-prefix: ewell;