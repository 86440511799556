@import '../../assets/theme/color.less';

.project-logo {
  flex: none;
  width: 80px;
  height: 80px;
  border-radius: 6px;
  background: @bg-1;
}
img.project-logo {
  object-fit: cover;
  background: @bg-1;
}

@app-prefix: ewell;@ant-prefix: ewell;