@import '../../assets/theme/color.less';

.mobile-menu-item-wrap {
  .mobile-menu-item {
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;

    .mobile-menu-item-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: @font-1;
    }

    .mobile-menu-item-arrow {
      width: 16px;
      height: 16px;
      transform: rotate(-90deg);
      transition: transform 0.5s ease;
      transform-origin: center center;
    }
    .mobile-menu-item-arrow-down {
      transform: rotate(0);
    }
    .mobile-menu-item-arrow-up {
      transform: rotateX(180deg);
    }

    &:active {
      background-color: @bg-10;
    }
  }
}

.mobile-menu-children-wrap {
  height: 0;
  overflow: hidden;

  .mobile-menu-children {
    margin: 0 16px;
    border-radius: 6px;
    overflow: hidden;
    transform: translate3d(0, -100%, 0);
    transition: transform 0.5s ease;
    border: 1px solid @border-1;
  }
}

.mobile-menu-children-wrap-active {
  height: auto;
  .mobile-menu-children {
    transform: translate3d(0, 0, 0);
  }
}

@app-prefix: ewell;@ant-prefix: ewell;