.tabs-ido-information-wrapper {
  gap: 24px;
  .ido-information-card-content-wrapper {
    padding: 8px 24px;
    .card-row {
      padding: 12px 0;
    }
  }
}

@media (max-width: 768px) {
  .tabs-ido-information-wrapper {
    flex-direction: column;
  }
}

@app-prefix: ewell;@ant-prefix: ewell;