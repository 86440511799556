@import '../../assets/theme/color.less';

.common-modal-frame {
  .common-modal-header {
    position: relative;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    width: 100%;
    margin-bottom: 24px;

    .common-modal-close {
      position: absolute;
      cursor: pointer;
      right: 0;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      width: 16px;
      height: 16px;
    }
  }

  .common-modal-content {
    margin-bottom: 24px;
  }

  .common-modal-footer {
    display: flex;
    .common-modal-btn {
      flex: 1;
      span {
        font-weight: 500;
      }
      &:first-child {
        margin-right: 16px;
      }
    }
  }
}

@app-prefix: ewell;@ant-prefix: ewell;