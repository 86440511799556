button.common-edit-button {
  border-radius: 6px;
  .large-icon {
    width: 20px;
    height: 20px;
  }
  .small-icon {
    width: 14px;
    height: 14px;
  }
}
@app-prefix: ewell;@ant-prefix: ewell;