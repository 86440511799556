.common-switch-drawer {
  border-radius: 8px 8px 0 0;
  .@{app-prefix}-drawer-header {
    padding: 24px 24px 0;
    border: none;
    .@{app-prefix}-drawer-title {
      text-align: center;
    }
  }
  .drawer-title {
    padding-left: 16px;
  }
  .close-icon {
    flex: none;
    width: 16px;
    height: 16px;
  }
}
@app-prefix: ewell;@ant-prefix: ewell;