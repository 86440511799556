@import '../../assets/theme/constants.less';
.footer {
  background-color: @bg-9;
  .footer-body {
    height: @footer-height;
  }

  .footer-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: @font-1;
    position: relative;
    z-index: 998;
    height: 100%;
    align-items: flex-end;
    padding: 12px 0;

    .community-warp {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .community-title {
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
      }

      .community-icon-list {
        .community-icon {
          width: 24px;
          height: 24px;
          margin-right: 12px;
          cursor: pointer;
        }
      }
    }

    .copyright-wrap {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.mobile-site {
  .footer-body {
    height: @mobile-footer-height;
  }
  .footer {
    .footer-row {
      display: flex;
      flex-direction: column;
      align-items: center;

      .community-warp {
        height: 52px;
        align-items: center;
      }
    }
  }
}

@app-prefix: ewell;@ant-prefix: ewell;