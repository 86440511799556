@import '../../../../assets/theme/color.less';

.my-wrap {
  display: inline-flex;
  height: 40px;
  margin-left: 24px;
  position: relative;

  .my-btn {
    padding: 0 20px;
    border: 1px solid @border-1;
    border-radius: 6px;
    align-items: center;
    display: flex;
  }

  .my-icon {
    height: 16px;
  }
  .my-label {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: @font-1;
  }

  .wallet-drawer {
    width: 280px;
    padding-top: 16px;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate3d(0, 100%, 0);
    z-index: 999;
    display: none;

    .wallet-drawer-box {
      border-radius: 6px;
      overflow: hidden;
      box-shadow: 0 4px 16px 0 rgb(7 10 38 / 16%);
      display: flex;
      flex-direction: column;
    }
  }

  .login-btn {
    display: inline-flex;
  }

  &:hover {
    .wallet-drawer {
      display: block;
    }
  }
}

.mobile-site {
  .my-wrap {
    height: 32px;
    margin-left: 8px;

    .my-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      padding: 0;
    }
  }
}

@app-prefix: ewell;@ant-prefix: ewell;