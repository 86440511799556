.project-info-container {
  padding-top: 24px;
  padding-bottom: 48px;

  .bread-wrap {
    margin-bottom: 24px;
  }
  .project-info-content {
    gap: 48px;
  }
}

@app-prefix: ewell;@ant-prefix: ewell;